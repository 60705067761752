

  export const WeldingImg1Data=[
    {
    id:1,
    img:require( '../Images/welding_img/Bimg1.jpg'),
    heading:"Air Pollution",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },
  {
    id:2,
    img:require( '../Images/welding_img/Bimg2.jpg'),
    heading:"Chemical Processing",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },
  {
    id:3,
    img:require( '../Images/welding_img/Bimg3.jpg'),
    heading:"Electronics & Semi-Conductors",
    text:'All our products go through various quality checks from procurement to final delivery.',
    
  },
  {
    id:4,
    img:require( '../Images/welding_img/BImg4.png'),
    heading:"Power Generation",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },
  {
    id:5,
    img:require( '../Images/welding_img/Bimg5.jpeg'),
    heading:"Pulp & Paper Mills",
    text:'All our products go through various quality checks from procurement to final delivery.',
    
  }, {
    id:6,
    img:require( '../Images/welding_img/Bimg6.jpg'),
    heading:"Water Systems",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },
  {
    id:7,
    img:require( '../Images/welding_img/Bimg7.png'),
    heading:"Water Systems",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },
  {
    id:8,
    img:require( '../Images/welding_img/Bimg8.jpg'),
    heading:"Water Systems",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },
  {
    id:9,
    img:require( '../Images/welding_img/Bimg9.jpg'),
    heading:"Water Systems",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },
  {
    id:10,
    img:require( '../Images/welding_img/Bimg10.png'),
    heading:"Water Systems",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },
  {
    id:11,
    img:require( '../Images/welding_img/Bimg11.jpg'),
    heading:"Water Systems",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },
  {
    id:12,
    img:require( '../Images/welding_img/Bimg12.jpg'),
    heading:"Water Systems",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },
  {
    id:12,
    img:require( '../Images/welding_img/Bimg13.png'),
    heading:"Water Systems",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },  {
    id:12,
    img:require( '../Images/welding_img/Bimg14.jpg'),
    heading:"Water Systems",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },  {
    id:12,
    img:require( '../Images/welding_img/Bimg15.png'),
    heading:"Water Systems",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },  {
    id:12,
    img:require( '../Images/welding_img/Bimg16.jpg'),
    heading:"Water Systems",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },  {
    id:12,
    img:require( '../Images/welding_img/Bimg17.png'),
    heading:"Water Systems",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },  {
    id:12,
    img:require( '../Images/welding_img/Bimg18.jpg'),
    heading:"Water Systems",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },  {
    id:12,
    img:require( '../Images/welding_img/Bimg19.jpg'),
    heading:"Water Systems",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },  {
    id:12,
    img:require( '../Images/welding_img/Bimg20.jpg'),
    heading:"Water Systems",
    text:'We stock huge inventories of all our products in all standard sizes to ensure timely delivery.',
  },
  ]